.notification {
  height: 100%;
  width: 100%;
  padding: 0px;

  &-title {
    display: flex;
    color: #58d4cc;
    margin-top: 10px;
    margin-bottom: 12px;
    margin-left: 10px;
    font-weight: 500;
  }

  &-button {
    color: #58d4cc;
    display: flex;
    // border: none;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }

  &-card {
    width: 100%;
    padding: 12px 0px;
    background: #f9fffd;
    border-radius: 6px;

    .message {
      width: 90%;

      .avatar {
        margin-left: 10px;
        float: left;
        width: 80px;
        height: 30px;
        border-radius: 6px;
        width: 100%;
        display: flex;

        .name {
          margin-left: 10px;
          margin-top: 5px;
          font-weight: 450;
          font-family: sans-serif;
        }

        .time {
          margin-left: 10px;
          margin-top: 8px;
          font-weight: 400;
          font-size: 10px;
          font-family: sans-serif;
        }
      }
    }

    .title {
      padding: 0px;
      margin-left: 10px;
      margin-top: 40px;

      .titleMessage {
        font-size: 14px;
        font-weight: 500;
        color: #58d4cc;
        margin-bottom: -5px;
        font-family: sans-serif;
      }

      .description {
        margin-top: 6px;
        font-size: 12px;
        font-family: sans-serif;
      }
    }
  }
}
