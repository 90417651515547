@media (max-width: 576px) {
  .loginPage {
    &-card {
      max-width: 90vw;
      &-left {
        display: none;
      }
      &-right {
        width: 100% !important;
        &-form {
          .loginForm {
            width: 90%;
          }
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .loginPage {
    &-card {
      max-width: 80vw;
      &-right {
        &-form {
          .loginForm {
            width: 70%;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .loginPage {
    &-card {
      max-width: 70vw;
      &-right {
        &-form {
          .loginForm {
            width: 60%;
          }
        }
      }
    }
  }
}

// @media (min-width: 992px) { ... }

// @media (min-width: 1200px) { ... }

// @media (min-width: 1400px) { ... }

.loginPage {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  background: $baseGrey;

  &-card {
    margin-top: 12vh;
    border-radius: 12px;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
    display: flex;
    overflow: hidden;

    &-left {
      height: 450px;
      width: 50%;
      overflow: hidden;
      img {
        height: 450px;
        object-fit: contain;
      }
    }

    &-right {
      width: 50%;
      &-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 32px 12px;
        overflow: hidden;
        border-radius: 6px;
        img {
          border-radius: 6px;
          width: 300px;
          object-fit: contain;
        }
      }

      &-form {
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        padding-top: 32px;

        .loginForm {
          .loginFormButton {
            margin-top: 6px;
            width: 100%;
            padding: 6px;
            height: auto;
            font-weight: bold;
          }
        }
      }
    }
  }
}
