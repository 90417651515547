.layout {
  height: 100vh;
  display: flex;
  background-color: #f0f2f5;
  &-container {
    min-height: 100vh;
    width: 100%;
    overflow-y: scroll;
    transition: all 0.3s;
    &-content {
      margin: 16px;
      .ant-card {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 6px;
      }
    }
    &-footer {
      font-size: 12px;
      text-align: center;
      margin-top: 38px;
      padding: 24px;
    }
  }
}

.sidebarDrawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-body {
      padding: 0px;
    }
  }
}
