.modalView {
  @media (max-width: 576px) {
    width: 520px !important;
  }
  @media (min-width: 576px) {
    width: 570px !important;
  }
  @media (min-width: 768px) {
    width: 720px !important;
  }
  @media (min-width: 992px) {
    width: 920px !important;
  }

  .ant-modal-body {
    padding: 0px;
  }
  &-header {
    background: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 24px;
    z-index: 1;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(128, 128, 128, 0.5);
    &-title {
      font-weight: 600;
      font-size: 18px;
      padding: 3px 0px;
    }
    &-status {
      display: flex;
      align-items: center;
      padding: 3px 0px;
      &-rate {
        margin-right: 12px;
        width: 135px;
      }
      &-active {
      }
    }
  }
  &-content {
    margin-top: 12px;
    &-image {
    }
    &-form {
      margin-top: 6px;
      padding: 24px;
      .titleForm {
        display: flex;
        justify-content: space-between;
        @media (max-width: 480px) {
          justify-content: start;
        }
      }
    }
  }
}
