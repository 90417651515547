.home {
  background-color: rgb(240 242 245);
  &-topContent {
    &-chart {
      background-color: white;
      margin-right: 6px;
      padding: 12px;
      border-radius: 6px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      &-title {
        text-align: center;
      }
      &-content {
        margin-top: 12px;
      }
    }
    &-table {
      background-color: white;
      padding: 12px;
      margin-left: 6px;
      border-radius: 6px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      min-height: 397.66px;
      &-title {
        text-align: center;
      }
      &-content {
        margin-top: 30px;
      }
    }
  }
  &-bottomContent {
    margin-top: 12px;
    &-count {
      .ant-card {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 6px;
      }
      &-item {
        background-color: white;
        padding: 6px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        &-icon {
          font-size: 32px;
        }
        &-value {
          font-size: 32px;
          font-weight: 600;
        }
      }
    }
    &-chart {
      background-color: white;
      padding: 12px;
      border-radius: 6px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      &-header {
        display: flex;
        justify-content: space-between;
        margin: 18px;
      }
      &-content {
        margin-top: 12px;
      }
    }
  }
}
