@import './resetCSS.scss';
@import './colors.scss';
@import '../views/auth/login/login.scss';
@import '../views/item/modalView.scss';
@import '../views/dashboard/dashboard.scss';
@import '../views/notifications/notification.scss';
@import '../views/changePassword/changePassword.scss';
@import '../layout/sidebar.scss';
@import '../layout/header.scss';
@import '../layout/layout.scss';
@import '../components/layout/layout.scss';
@import '../components/input/simpleFormEditor.scss';
@import '~braft-editor/dist/index.css';

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

html {
  font-family: 'Noto Sans', sans-serif;
}

.form-item {
  .ant-skeleton-element {
    width: 100%;
  }
}

.hidden-form {
  display: none;
}

.disabledShow {
  color: rgba(0, 0, 0, 0.85) !important;
  background-color: white !important;
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: rgba(0, 0, 0, 0.85) !important;
    background-color: white !important;
  }
  .ant-input-disabled {
    color: rgba(0, 0, 0, 0.85) !important;
    background-color: white !important;
  }
  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.85) !important;
    background-color: white !important;
  }
}

.largeModal {
  @media (max-width: 576px) {
    width: 520px !important;
  }
  @media (min-width: 576px) {
    width: 570px !important;
  }
  @media (min-width: 768px) {
    width: 720px !important;
  }
  @media (min-width: 992px) {
    width: 920px !important;
  }
}

.modalPrint {
  @media (max-width: 576px) {
    width: 520px !important;
  }
  @media (min-width: 576px) {
    width: 570px !important;
  }
  @media (min-width: 768px) {
    width: 720px !important;
  }
  @media (min-width: 992px) {
    width: 920px !important;
  }

  .ant-modal-body {
    padding: 0px;
  }
}

.pointer {
  cursor: pointer;
}
