.header {
  background-color: #370033;
  padding: 6px 12px;
  position: sticky;
  top: 0;
  z-index: 3;
  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-left {
      display: flex;
      padding: 6px;
      align-items: center;
      &-menu {
        font-size: 16px;
        color: white;
        cursor: pointer;
      }
      &-logo {
        transition: all 0.3s;
        margin-left: 18px;
        overflow: hidden;
        img {
          height: 40px;
          object-fit: cover;
          overflow: hidden;
        }
      }
    }
    &-right {
      display: flex;
      align-items: center;
      max-width: 100px;
      padding: 6px;
      width: 100%;
      &-notif {
        &-list {
          right: -33px;

          &-title {
            // display: flex;
            color: black;
            margin-bottom: 10px;
            margin-left: 16px;
            font-weight: 500;
          }

          .content {
            display: flex;
            padding: 0px;
            // margin-top: 5px;

            .message {
              padding: 12px;

              .header {
                display: flex;

                .avatar {
                  margin-right: 12px;
                }

                .name {
                  display: flex;
                  margin-right: 20px;
                  font-weight: 450;
                  justify-content: center;
                  align-items: center;
                  font-size: 14px;
                  color: white;
                }

                .time {
                  font-size: 10px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-weight: 400;
                  color: #dbe1df;
                }
              }

              .titleMessage {
                font-size: 14px;
                margin-top: 6px;
                font-weight: 450;
                color: black;
                margin-bottom: -10px;
              }

              .description {
                margin-top: 6px;
                font-size: 12px;
                color: #555958;
              }
            }
          }
        }
      }
      &-profile {
      }
    }
  }
}
