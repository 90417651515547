.form-editor {
  border: 1px solid #d1d1d1;
  border-radius: 5px;

  .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid #ccc;
  }
  .ql-container.ql-snow {
    border: none;
  }
}

.bf-container,
.form-editor {
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  // height: 380px;
}
