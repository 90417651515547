.sidebar {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background: white;
  transition: all 0.3s;

  &-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 130px;
    img {
      height: 160px;
      object-fit: cover;
      overflow: hidden;
    }
  }
  &-menu {
    // color: white;
    padding: 0px 12px;
    .ant-menu {
      // background: #f68738;
      // color: white;
      font-weight: 500;
    }
    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
      border-right: none;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #00a75a;
    }

    .ant-menu-item-selected {
      color: white;
    }

    .ant-menu-item-selected:hover {
      color: white;
    }
  }
}

// .sidebar::-webkit-scrollbar-track {
//   background-color: #b8a89c;
// }
.sidebar::-webkit-scrollbar {
  width: 8px;
  background-color: white;
}
.sidebar::-webkit-scrollbar-thumb {
  background-color: #00a75a;
  border-radius: 12px;
  visibility: hidden;
}
.sidebar:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
